import React from "react"
import { connect, useSelector } from "react-redux"
import { CFDsQuery } from "../../prismic/staticQueries"

import Layout from "../../layout"
import {ContentBasic} from "../../components/common"

const CFDs = () => {
  const language = useSelector(state => state.language)
  const CFDsData = CFDsQuery(language)
  return (
    <Layout>
      <ContentBasic {...CFDsData} />
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}
export default connect(mapStateToProps)(CFDs)
